import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Link } from '~components'
import Hero from './style'
export default function HeroSection(){
return(
<Hero backgroundColor="rgba(21, 205, 168, 0.2)">
  <Container>
    <Row className="align-items-center justify-content-center">
      {/* Welcome content Area */}
      <Col className="col-xxl-8 col-lg-7 col-md-9 col-xs-11 order-2 order-lg-1">
        <Hero.Content>
          {/* <Hero.SubTitle fontColor="#ff7272"  as="h3">Get Started</Hero.SubTitle> */}
          <Hero.Title as="h1" className="hero-header-title">Engage and grow your network of support</Hero.Title>
          <Hero.Text fontColor="rgba(38, 39, 41, 0.7)">When caring for someone with Alzheimer's, it can be hard to know what to do next.
          SupportTree provides caregiving tools to make it easier for caregivers, providing the information needed and a support team that has you and your loved one's best intentions in mind. <a href="#join-our-community">Find out More.</a></Hero.Text>
          <Hero.ButtonGroup>
            {/* <Hero.Button className="btn-red 🌱" as={Link} to="/">Watch Video</Hero.Button> */}
            <Hero.Button className="btn-green" as={Link} to="#join-our-community">Get Started</Hero.Button>
          </Hero.ButtonGroup>
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
      {/*/ .Welcome img Area */}
      <Col xs="12" className="col-xxl-4 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2">
        <Hero.ImageGroup>
            <Hero.ImageOne>
              <Img className="w-40" src="../../../assets/image/home-app/support-tree-hero-app.png" alt="app" placeholder="blurred"/>
            </Hero.ImageOne>
            {/* <Hero.ImageTwo>
              <Img className="w-100" src="../../../assets/image/home-app/hero-l8-2.png" alt="app-img" layout="fullWidth" placeholder="blurred"/>
            </Hero.ImageTwo> */}
        </Hero.ImageGroup>
      </Col>
      {/*/ .Welcome img Area */}
    </Row>
  </Container>
</Hero>
)
}