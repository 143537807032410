import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ServicesSection(){
return(
<Service>
  <Container className="container">
    <Row className="row justify-content-center">
      <Col className="col-xl-8 text-center">
        <SectionTitle
        title="Features Created Uniquely For You"
        titleProps={{mb:"40px", mbLG:"75px"}}
        subTitleProps={{mb:"20px"}}
        />
      </Col>
    </Row>
    <Row className="row justify-content-center">
      {/* Single Services */}
      <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="Cargivers" icon="fas fa-bell"  iconColor="#87c987"  text="Care plan and activites communicated to your network of supporters" />
      </Col>
      {/*/ .Single Services */}
      {/* Single Services */}
      <Col className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="Supporters" icon="fas fa-layer-group"  iconColor="#87c987"  text="Know exactly what is needed, when to help and a timeline you can rely on to stay up-to-date" />
      </Col>
      {/*/ .Single Services */}
      <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="Communication" icon="fas fa-envelope"  iconColor="#87c987"  text="Inbound requests to visit and support without the friction. Outbound requests to the community, when needed" />
      </Col>
      {/*/ .Single Services */}
      {/*/ .Single Services */}
      <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="Community" icon="fas fa-chart-pie"  iconColor="#87c987"  text="Educational tools and resources, along with a network of other caregivers with shared experiences" />
      </Col>
      {/*/ .Single Services */}
    </Row>
  </Container>
</Service>
)
}

