import React from 'react'
import SectionTitle from './components/SectionTitle'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './components/Widget'
import Content from './style'
export default function ContentSectionOne(){
return(
<Content backgroundColor="#f2f5fb">
  <Container>
    <Row className="row align-items-center justify-content-center">
      {/* about-us Content */}
      <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-10 order-2 orde-lg-1">
        <Content.Box mbLG="70px" mb="35px">
        <SectionTitle
        subTitle="About SupportTree"
        title="We believe in community."
        text="Everyone understands the high emotional stress resulting from being a primary caregiver and at SupportTree, we believe this is a burden that shouldn’t be carried alone. SupportTree is designed to lower social barriers and give friends, family, and caregivers the collaborative tools to collectively care for someone living with Alzheimer's."
        subTitleProps={{mb:"35px"}}
        titleProps={{mb:"20px"}}
        />
        </Content.Box>
        {/* <Content.Box>
          <Row className=" justify-content-center">
            <Col className="col-lg-6 col-xs-6">
              <Widget icon="far fa-envelope" title="Minimal Design" text="Generate Lorem Ipsum place holder<br class='d-none d-xl-block'> text for use in your Explore" />
            </Col>
            <Col className="col-lg-6 col-xs-6">
            <Widget icon="fa fa-layer-group" title="Best Material" text="Generate Lorem Ipsum place holder<br class='d-none d-xl-block'> text for use in your Explore" />
            </Col>
          </Row>
        </Content.Box> */}
      </Col>
      {/*/ .about-us Content */}
      <Col xs="12" className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.ImageGroup>
        <Img src="../../../assets/image/support-tree-logo.svg" alt="content" layout="fullWidth" placeholder="blurred" style={{ marginBottom: '100px' }}/>
          {/* <Content.ImageShape>
            <Img src="../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          </Content.ImageShape> */}
        </Content.ImageGroup>
      </Col>
    </Row>
  </Container>
</Content>

)
}